import React, {useCallback, useEffect, useState} from 'react';
import { observer } from 'mobx-react-lite';
import { ProductLineEditorInterface } from '../Hooks/useProductLine';
import useTypes from '../../../../../../../Type/Api/useTypes';
import RichtextEditor from '../../../../../../../../../../@Future/Component/Generic/Input/RichtextEditor/RichtextEditor';
import { CommitBuilder } from '../../../../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import FieldView from '../../../../../../../Field/View/FieldView';
import { setValueByFieldInEntity } from '../../../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import CollapsibleToolbar from "../../../../../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/CollapsibleToolbar";
import DefaultToolbarButtons from "../../../../../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/DefaultToolbarButtons";
import EditorButton from "../../../../../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorCommandButton";
import EditorFontSelector from "../../../../../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorFontSelector";
import EditorColorPicker from "../../../../../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorColorPicker";

export interface ProductLineExtraDescriptionProps
{
    productLine: ProductLineEditorInterface;
    disabled?: boolean;
}

export const ProductLineExtraDescription: React.FC<ProductLineExtraDescriptionProps> = observer(
    ({
        productLine,
        disabled
    }) =>
    {
        const types = useTypes();
        const [ extraDescription, setExtraDescription ] =
            useState(
                productLine.productLine.getObjectValueByField<string>(
                    types.ProductLine.Field.ExtraDescription
                )
            );

        useEffect(
            () =>
            {
                setExtraDescription(
                    productLine.description.extraDescription
                );
            }, [
                productLine,
                disabled
            ]);

        const onBlurExtraDescription =
            useCallback(
                () =>
                {
                    setValueByFieldInEntity(
                        productLine.productLine,
                        types.ProductLine.Field.ExtraDescription,
                        extraDescription,
                        productLine.commitContext
                    )
                    const builder = new CommitBuilder(productLine.commitContext)
                    builder.commit()
                        .then();
                },
                [
                    productLine,
                    types,
                    extraDescription
                ]
            );

        return disabled
            ? <FieldView
                entity={productLine.productLine}
                field={types.ProductLine.Field.ExtraDescription}
                commitContext={productLine.commitContext}
            />
            : <RichtextEditor
                showToolbarOnFocus
                value={extraDescription}
                onChange={setExtraDescription as any}
                onBlur={onBlurExtraDescription}
            >
                <CollapsibleToolbar>
                    <DefaultToolbarButtons />
                    <EditorButton icon="link" tooltip="Link" command="link"/>
                    <EditorFontSelector />
                    <EditorColorPicker tooltip="Selecteer kleur" />
                </CollapsibleToolbar>
            </RichtextEditor>;
    }
);